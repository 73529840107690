import { SignIn } from '@clerk/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import Head from 'next/head';

export default function Page() {
  const queryClient = useQueryClient();
  // We're clearing the query cache here again just in case any items were cached between lougout and Login page rendering
  queryClient.clear();
  return (
    <div className={'flex h-full min-h-screen w-full flex-col items-center justify-center pt-4'}>
      <Head>
        <title>Complete</title>
      </Head>
      <SignIn
        appearance={{
          elements: {
            // hides the 'Sign up' link
            footer: 'hidden',
          },
        }}
      />
    </div>
  );
}
